import { defineStore } from 'pinia';
import getPageQs from '~/helpers/getPageQs.js';

export const useClickStore = defineStore('click', () => {
  const route = useRoute();
  const crm_click_id = ref();
  const { $api } = useNuxtApp();
  const referrerCookie = useCookie('referrer');

  const fetchCrmClickId = async () => {
    try {
      const pageQs = getPageQs();
      const query =
        Object.keys(route.query).length > 0
          ? { ...route.query, calc_id: pageQs.calc_id, email_id: pageQs.email_id }
          : pageQs;
      const { data } = await $api.lkServer().saveClick({
        ...query,
        referrer: referrerCookie.value,
      });
      crm_click_id.value = data.data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    fetchCrmClickId,
    crm_click_id,
  };
});
